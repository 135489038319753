import React, { ReactNode } from 'react'
import {
  IInventoryAdmin,
  IInventoryStatus,
  InventoryStatusTypes,
  InventoryType,
} from '@/types'
import { ToggleButton } from '@/components'
import {
  ArrowPathIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useInventorySync } from '@/apis/hooks/inventory'
import { useToast } from '@/hooks'
import InventorySearch from '@/pages/AIJobs/components/inventorySearch'
import { Menu } from '@headlessui/react'
import { EllipsisVerticalIcon, FolderIcon } from '@heroicons/react/24/outline'
import moment from 'moment/moment'
import { DATE_FORMAT } from '@/constants'

const statusMap: Record<IInventoryStatus, string> = {
  active: 'fill-green-500',
  inactive: 'fill-red-500',
  pending: 'fill-yellow-500',
}

const ListItemDropdown = ({ children }: { children: ReactNode }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
        {children}
      </Menu.Items>
    </Menu>
  )
}
const ListDropdownItem = ({
  title,
  Icon,
  onClick,
}: {
  title: ReactNode
  Icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>
  >
  onClick?: () => void
}) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          onClick={onClick}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'group flex items-center px-4 py-2 text-sm',
          )}
        >
          <Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
          {title}
        </div>
      )}
    </Menu.Item>
  )
}

const StatusIndicator = ({ status }: { status: InventoryStatusTypes }) => {
  return (
    <svg
      className={classNames('h-2 w-2', statusMap[status])}
      viewBox="0 0 6 6"
      aria-hidden="true"
    >
      <circle cx={3} cy={3} r={3} />
    </svg>
  )
}

const ListItem = ({
  item,
  onEdit,
  onToggle,
  onView,
  onSearchClick,
  onDelete,
  onViewFiles,
  onAudit,
  ...rest
}: {
  item: IInventoryAdmin
  onToggle: () => void
  onEdit: (data: IInventoryAdmin) => void
  onDelete: (data: IInventoryAdmin) => void
  onView: (id: string) => void
  onSearchClick: React.MouseEventHandler<SVGSVGElement>
  onAudit: (mappingId: string) => void
  onViewFiles: (
    organizationId: string,
    vendor: InventoryType,
    dealer: string,
  ) => void
}) => {
  const { addToast } = useToast()
  const onSyncSuccess = () => {
    addToast('success', 'Inventory sync has been queued')
  }
  const [syncInventory] = useInventorySync(onSyncSuccess)
  const checked = item.status === 'active'
  return (
    <>
      <tr className="my-2" {...rest}>
        <td>
          <div className="flex gap-2 items-center">
            <StatusIndicator status={item.status} />
            {item.organization ? item.organization.name : '-'}
          </div>
        </td>
        <td>{item.vendor}</td>
        <td>{item.dealer_id}</td>
        <td>
          <ToggleButton
            checked={checked}
            onChange={() => onToggle()}
            disabled={!checked}
          />
        </td>
        <td>
          {item.status === 'inactive' || !item.synced_at
            ? '-'
            : moment(item.synced_at).format(DATE_FORMAT)}
        </td>
        <td>
          {item.status === 'inactive' || !item.modifiedAt
            ? '-'
            : moment(item.modifiedAt).format(DATE_FORMAT)}
        </td>
        <td>
          <ListItemDropdown>
            <ListDropdownItem
              title="Edit"
              Icon={PencilSquareIcon}
              onClick={() => onEdit(item)}
            />

            <ListDropdownItem
              title="Sync inventory"
              Icon={ArrowPathIcon}
              onClick={() =>
                item.organization &&
                syncInventory({
                  pathParams: { organization: item.organization._id },
                })
              }
            />
            <ListDropdownItem
              title="Imports"
              Icon={EyeIcon}
              onClick={() => onView(item.id)}
            />
            <ListDropdownItem
              title="Files"
              Icon={FolderIcon}
              onClick={() =>
                onViewFiles(
                  item.organization?._id || '',
                  item.vendor,
                  item.dealer_id,
                )
              }
            />
            <ListDropdownItem
              title="Audit trail"
              Icon={CodeBracketIcon}
              onClick={() => onAudit(item.id || '')}
            />
            <ListDropdownItem
              title="Delete"
              Icon={TrashIcon}
              onClick={() => onDelete(item)}
            />
          </ListItemDropdown>

          <MagnifyingGlassIcon
            className="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-500"
            onClick={onSearchClick}
          />
        </td>
      </tr>
      <tr className="inventory-search-row">
        <td colSpan={7}>
          <div className="w-full">
            <InventorySearch
              horizontal
              organization={item?.organization?._id}
              vendor={item.vendor}
            />
          </div>
        </td>
        <td className="hidden"></td>
        <td className="hidden"></td>
        <td className="hidden"></td>
        <td className="hidden"></td>
        <td className="hidden"></td>
        <td className="hidden !p-0"></td>
      </tr>
    </>
  )
}

export default ListItem
