import React, { useMemo } from 'react'
import moment from 'moment'

import {
  TextLink,
  DataTable,
  WithPaginator,
  TableLoadingSkeleton,
  Badge,
} from '@/components'
import { generatePath, joinArray, organizationsPath } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { useLeads } from '@/hooks'
import { ILead, TTaskStatus } from '@/types'

import { headers, Filters } from './'

export const LeadsList: React.FC = () => {
  const {
    filters,
    onResetFilters,
    onUpdateFilters,
    isLoading,
    totalItems,
    onPageMetaChange,
    data,
    pageMeta,
  } = useLeads()

  const fakeLeads = useMemo(() => [...new Array(10).fill(1)], [])

  const pageData: ILead[] = useMemo(() => {
    if (isLoading) return fakeLeads
    return data[pageMeta.page] ?? []
  }, [data, fakeLeads, isLoading, pageMeta.page])

  return (
    <WithPaginator
      data={pageData}
      loading={isLoading}
      totalItems={totalItems}
      initialPage={pageMeta.page}
      onPageMetaChange={onPageMetaChange}
      initialPageSize={pageMeta.pageSize}
    >
      {pagedData => (
        <>
          <Filters
            filters={filters}
            onReset={onResetFilters}
            onUpdate={onUpdateFilters}
          />
          <DataTable data={pagedData} headers={headers}>
            {data =>
              isLoading ? (
                <TableLoadingSkeleton
                  rows={10}
                  className="py-4"
                  cols={headers.length}
                />
              ) : (
                data.map((datum, idx) => <ListItem key={idx} {...datum} />)
              )
            }
          </DataTable>
        </>
      )}
    </WithPaginator>
  )
}

export const ListItem: React.FC<Partial<ILead>> = ({ ...item }) => {
  return (
    <tr className="my-2">
      <td>
        <div className="flex items-center gap-x-4">
          {joinArray(' ', item?.firstName, item?.lastName)}
        </div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black">
          {item.phoneNumber}
        </div>
      </td>
      <td>
        <div className="font-mono text-sm leading-6 text-black capitalize">
          {item.crm}
        </div>
      </td>
      <td>
        <TextLink
          to={generatePath(organizationsPath, { id: item?.organization?._id })}
        >
          {item?.organization?.name}
        </TextLink>
      </td>
      <td>
        <TextLink disabled to={generatePath('', { id: item?.location?._id })}>
          {item?.location?.name}
        </TextLink>
      </td>
      <td>{moment(item.createdAt).format(DATE_FORMAT)}</td>
      <td>
        {item.isImported ? (
          <Badge status={TTaskStatus.COMPLETED} label="Imported" />
        ) : undefined}
      </td>
    </tr>
  )
}
