import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { useParams } from 'react-router-dom'

import {
  useEditWorkflow,
  useWorkflowUsers,
  useCreateWorkflow,
  useWorkflowTemplates,
  useGetSingleWorkflow,
} from '@/apis'
import {
  IUser,
  ISample,
  ITemplate,
  IWorkflow,
  IUserResponse,
  IWorkflowState,
  IWorkflowTemplateResponse,
  TPingTestType,
} from '@/types'
import { defaultCombinator } from '@/pages/Workflow/components/constants'

type TPayloadState = IWorkflowState & {
  location: string
  template: ITemplate
}

interface IWorkflowContext {
  users: IUser[]
  onSave: () => void
  samples: ISample[]
  workflow?: IWorkflow
  templates: ITemplate[]
  saveState: TPingTestType
  currentStep: '1' | '2' | '3'
  payload: Partial<TPayloadState>
  toStep: (_step: '1' | '2' | '3') => void
  onUpdateSamples: (_samples: ISample[]) => void
  onUpdatePayload: (_payload: Partial<TPayloadState>) => void
}

export const LocalWorkflowContext = createContext({} as IWorkflowContext)

export const LocalWorkflowContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { workFlowId, id } = useParams()
  const [users, setUsers] = useState<IUser[]>([])
  const [samples, setSamples] = useState<ISample[]>([])
  const [workflow, setWorkflow] = useState<IWorkflow>()
  const [templates, setTemplates] = useState<ITemplate[]>([])
  const [currentStep, setStep] = useState<'1' | '2' | '3'>('1')
  const [saveState, setSaveState] = useState<TPingTestType>('pending')
  const [payload, setPayload] = useState<Partial<TPayloadState>>({})

  const onSingleWorkflowSuccess = useCallback((data: IWorkflow) => {
    setWorkflow(data)
    const _payload = {
      name: data?.name,
      message: data?.message,
      actions: data?.actions,
      location: data?.location,
      sendAfter: data?.sendAfter,
      combinator: data?.combinator ?? defaultCombinator,
    }
    setPayload(_payload)
    setStep('2')
  }, [])

  const onGetUsersSuccess = useCallback((data: IUserResponse) => {
    setUsers(data.users)
  }, [])

  const [getWorkflow] = useGetSingleWorkflow(onSingleWorkflowSuccess)
  const [getUsers] = useWorkflowUsers(onGetUsersSuccess)
  const { mutateAsync: createWorkflow } = useCreateWorkflow()
  const { mutateAsync: editWorkflow } = useEditWorkflow()

  const fetchWorkflowData = useCallback(() => {
    getWorkflow({ pathParams: { id: workFlowId } })
  }, [getWorkflow, workFlowId])

  const fetchOrganizationData = useCallback(() => {
    if (!id) return
    getUsers({ pathParams: { organization: id } })
  }, [id, getUsers])

  useEffect(() => {
    fetchOrganizationData()
  }, [fetchOrganizationData])

  useEffect(() => {
    if (workFlowId !== 'new') fetchWorkflowData()
  }, [workFlowId, fetchWorkflowData])

  const onUpdatePayload = useCallback((_payload: Partial<TPayloadState>) => {
    setPayload(prev => ({ ...prev, ..._payload }))
  }, [])

  const toStep = useCallback((step: '1' | '2' | '3') => {
    setStep(step)
  }, [])

  const onSave = useCallback(async () => {
    const request = workflow ? editWorkflow : createWorkflow
    const { _id: workflowId } = workflow || {}
    const {
      name,
      message,
      actions,
      template,
      location,
      sendAfter,
      combinator,
    } = payload

    console.log({
      name,
      message,
      actions,
      template,
      location,
      sendAfter,
      combinator,
    })
    if (
      (!name ||
        !actions ||
        !template ||
        !location ||
        !sendAfter ||
        !combinator) &&
      template?.type !== 'appointmentConfirmation'
    ) {
      return
    }
    setStep('3')
    setSaveState('pending')
    try {
      name &&
        (await request({
          params: {
            workflowId,
            locationId: location,
          },
          name,
          actions,
          message,
          sendAfter,
          combinator: combinator || defaultCombinator,
          crm: template.crm,
          type: template.type,
          workflowTemplate: template?.id,
          flags: template.flags,
        }))
      setSaveState('completed')
    } catch (err: any) {
      setStep('2')
      setSaveState('failed')
    }
  }, [workflow, editWorkflow, createWorkflow, payload])

  const onTemplatesSuccess = useCallback(
    (data: IWorkflowTemplateResponse) => {
      setTemplates(data.templates)
      onUpdatePayload({
        template: data.templates.find(
          t => t.id === (payload.template?.id ?? workflow?.workflowTemplate),
        ),
      })
    },
    [workflow, onUpdatePayload, payload.template?.id],
  )

  const [getTemplates] = useWorkflowTemplates(onTemplatesSuccess)

  useEffect(() => {
    if (!payload?.location) return
    getTemplates({ pathParams: { id: payload?.location } })
  }, [getTemplates, payload?.location])

  const onUpdateSamples = useCallback((_samples: ISample[]) => {
    setSamples(_samples)
  }, [])

  const value = useMemo(
    () => ({
      users,
      onSave,
      toStep,
      samples,
      payload,
      workflow,
      templates,
      saveState,
      currentStep,
      onUpdateSamples,
      onUpdatePayload,
    }),
    [
      users,
      onSave,
      toStep,
      samples,
      payload,
      workflow,
      templates,
      saveState,
      currentStep,
      onUpdateSamples,
      onUpdatePayload,
    ],
  )

  return (
    <LocalWorkflowContext.Provider value={value}>
      {children}
    </LocalWorkflowContext.Provider>
  )
}
