import { IDataTableHeader } from '@/components'
import { ILead } from '@/types'

export const headers: IDataTableHeader<ILead>[] = [
  {
    key: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    sortable: false,
    key: 'phoneNumber',
    label: 'Phone number',
  },
  {
    key: 'crm',
    label: 'CRM',
    sortable: false,
  },
  {
    sortable: false,
    key: 'organizationId',
    label: 'Organization',
  },
  {
    sortable: false,
    key: 'locationId',
    label: 'Location',
  },
  {
    sortable: false,
    key: 'createdAt',
    label: 'Created At',
  },
  {
    sortable: false,
    key: 'isImported',
    label: 'Imported to Matador',
  },
]
